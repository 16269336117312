<div class="bg-color-black-7 my-customer-tab">
  @if (isInitialized) {
    <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="30" [fromRoot]="true" [infiniteScrollContainer]="selector"
      (scrolled)="loadMore()">
      <div class="container padding-top-tiny-5">
        <div class="row">
          <div class="col-12">
            <!-- start filters -->
            <form class="filter-panel__form">
              <!-- main filters -->
              <div class="row">
                <div class="col-12" [class.col-sm-6]="showCountries">
                  <hl-search-field [(searchInput)]="searchInput" (searchInputChange)="onAdvancedFilterChange()" searchFieldClasses="textfield--large"
                    placeholder="{{'SEARCH_TERM' | translate}}"></hl-search-field>
                </div>
                @if (showCountries) {
                  <div class="col-12 col-sm-6">
                    <hl-selectbox [options]="countries" label="COUNTRY" [(value)]="selectedCountry"
                      (valueChange)="onSelectedCountryChanged()"></hl-selectbox>
                  </div>
                }
              </div>
            </form>
            <!-- end filters -->
          </div>
        </div>
      </div>
    </div>
  }
  <div class="height-100">
    @if (!showFavoriteFilters) {
      <hl-list [items]="viewModelList" [filteredLengthWithoutPagination]="filteredLengthWithoutPagination"
        [additionalCollectionClass]="'overview-list--equipment'" [isLoaded]="isLoaded" [sortConfig]="sortSkeleton"
        (onSortOptionChange)="onSortChange($event)"
        [label]="filteredLengthWithoutPagination | plural : 'GENERIC_LABEL_CUSTOMERS_SINGULAR' : 'GENERIC_LABEL_CUSTOMERS' | async"
        [trackByFunction]="trackByFn" empty="NO_CUSTOMERS_FOR_COUNTRY">
        <ng-template let-item let-index="index">
          <div class="collection-item list-item list-item--customer-impersonation">
            <div class="collection__content">
              <p class="list-item__title">{{ item.customerName }}</p>
              <ul class="bare-list info-list">
                <li class="info-list__item">
                  <i class="icon-location-pin" hlTooltip [title]="'GENERIC_LABEL_STREET' | translate" aria-hidden="true"></i>
                  {{ item.street }}
                </li>
                <li class="info-list__item">
                  <i class="icon-department" hlTooltip [title]="'GENERIC_LABEL_CITY' | translate" aria-hidden="true"></i>
                  {{ item.city + ((item.state) ? (', ' + item.state) : '') }}
                </li>
                <li class="info-list__item">
                  <i class="icon-email" hlTooltip [title]="'GENERIC_LABEL_POSTAL_CODE' | translate" aria-hidden="true"></i>
                  {{ item.postalCode }}
                </li>
              </ul>
              <span>
              </span>
            </div>
            <div class="collection__additional-content list-item__toolbar align-items-center">
              <input type="checkbox" class="toggle-switch__checkbox" [id]="'my-customers-tab-toggle-' + index" [checked]="item.myCustomer"
                (change)="toggleMyCustomer($event, item)">
              <label [for]="'my-customers-tab-toggle-' + index" class="toggle-switch__label my-equipment-switch__label"></label>
            </div>
          </div>
        </ng-template>
        <ng-template hlNonEquipmentTemplate>
          <div class="col col-sm-auto padding-0">
            <div class="float-right">
              @if (hasMoreItemsThanOne()) {
                @if (isSelectAllAvailable()) {
                  <button class="button button--small margin-left-tiny-5" (click)="selectAllItems()"
                    [translate]="'GENERIC_LABEL_SELECT_ALL'"></button>
                }
                @if (isDeselectAllAvailable()) {
                  <button class="button button--small margin-left-tiny-5" (click)="unselectAllItems()"
                    [translate]="'GENERIC_LABEL_DESELECT_ALL'"></button>
                }
              }
            </div>
          </div>
        </ng-template>
      </hl-list>
    } @else {
      <hl-filter-tabs [listType]="listType" [isMyInstitutions]="true">
        <div main-filter>
          <hl-list [items]="viewModelList" [filteredLengthWithoutPagination]="filteredLengthWithoutPagination"
            [additionalCollectionClass]="'overview-list--equipment'" [isLoaded]="isLoaded" [sortConfig]="sortSkeleton"
            (onSortOptionChange)="onSortChange($event)"
            [label]="filteredLengthWithoutPagination | plural : 'GENERIC_LABEL_CUSTOMERS_SINGULAR' : 'GENERIC_LABEL_CUSTOMERS' | async"
            [trackByFunction]="trackByFn" empty="NO_CUSTOMERS_FOR_COUNTRY">
            <ng-template let-item let-index="index">
              <div class="collection-item list-item list-item--customer-impersonation">
                <div class="collection__content">
                  <p class="list-item__title">{{ item.customerName }}</p>
                  <ul class="bare-list info-list">
                    <li class="info-list__item">
                      <i class="icon-location-pin" hlTooltip [title]="'GENERIC_LABEL_STREET' | translate" aria-hidden="true"></i>
                      {{ item.street }}
                    </li>
                    <li class="info-list__item">
                      <i class="icon-department" hlTooltip [title]="'GENERIC_LABEL_CITY' | translate" aria-hidden="true"></i>
                      {{ item.city + ((item.state) ? (', ' + item.state) : '') }}
                    </li>
                    <li class="info-list__item">
                      <i class="icon-email" hlTooltip [title]="'GENERIC_LABELc_POSTAL_CODE' | translate" aria-hidden="true"></i>
                      {{ item.postalCode }}
                    </li>
                  </ul>
                  <span>
                    </span>
                </div>
                <div class="collection__additional-content list-item__toolbar align-items-center">
                  <input type="checkbox" class="toggle-switch__checkbox" [id]="'my-customers-tab-toggle-' + index" [checked]="item.myCustomer"
                    (change)="toggleMyCustomer($event, item)">
                  <label [for]="'my-customers-tab-toggle-' + index" class="toggle-switch__label my-equipment-switch__label"></label>
                </div>
              </div>
            </ng-template>
            <ng-template hlNonEquipmentTemplate>
              <div class="col col-sm-auto padding-0">
                <div class="float-right">
                  @if (hasMoreItemsThanOne()) {
                    @if (isSelectAllAvailable()) {
                      <button class="button button--small margin-left-tiny-5" (click)="selectAllItems()"
                        [translate]="'GENERIC_LABEL_SELECT_ALL'"></button>
                    }
                    @if (isDeselectAllAvailable()) {
                      <button class="button button--small margin-left-tiny-5" (click)="unselectAllItems()"
                        [translate]="'GENERIC_LABEL_DESELECT_ALL'"></button>
                    }
                  }
                </div>
              </div>
            </ng-template>
          </hl-list>
        </div>
        <div saved-filters>
          @if (isConfigLoaded) {
            <hl-saved-filters (onFavoriteSelect)="applyFavorite($event)" (onFavoriteRename)="renameFavoriteModal.renameFavorite($event)"
              (onFavoriteDelete)="deleteFavoriteModal.deleteFavorite($event)" [filterObject]="getFilterObject()" [listType]="listType"
              [isMyInstitutions]="true"></hl-saved-filters>
          }
        </div>
      </hl-filter-tabs>
    }
  </div>
</div>

@if (isConfigLoaded) {
  <hl-rename-favorite-modal #renameFavoriteModal></hl-rename-favorite-modal>
  <hl-add-favorite-modal #addFavoriteModal [filter]="getFilterObject()" [type]="listType"></hl-add-favorite-modal>
  <hl-delete-favorite-modal #deleteFavoriteModal></hl-delete-favorite-modal>
}
