import { filter, isArrayLike, isNil, matches } from 'lodash-es';

export function filterDataset<T, Key extends keyof T>(dataset: T[], key: Key, value: T[Key] | T[Key][]): T[] {
  if (isNil(value) || (isArrayLike(value) && value.length === 0)) {
    return dataset;
  }

  if (Array.isArray(value)) {
    return filter(dataset, item => value.indexOf(item[key]) !== -1);
  }

  return filter(dataset, matches({[key]: value}))
}

type Entries<T> = { [Key in keyof T]: [Key, T[Key]]; }[keyof T][];
export type Filter<T> = Partial<{ [Key in keyof T]: T[Key] | T[Key][] }>;

export function filterDatasetBy<T>(dataset: T[], filters: Filter<T>): T[] {
  return (Object.entries(filters) as Entries<T>)
    .reduce((filteredDataset, [key, value]) => filterDataset(filteredDataset, key, value), dataset);
}

