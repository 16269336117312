import { asyncScheduler, combineLatest, EMPTY, identity, merge, Observable, SchedulerLike, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, startWith, switchMap, tap } from 'rxjs/operators';

export const enhance =
  <TSource, TEnhancer, TResult>(enhancer$: Observable<TEnhancer>, project?: ([source, result]: [TSource, TEnhancer]) => TResult) => {
    project = project || (identity as ([source, result]: [TSource, TEnhancer]) => TResult);
    return (source$: Observable<TSource>) => {
      let joinEmitted = false;
      const join$ = combineLatest([source$, enhancer$]).pipe(
        map(project),
        tap(() => joinEmitted = true)
      );
      // tslint:disable-next-line: rxjs-no-unsafe-scope
      const sourceFiltered$ = source$.pipe(skipWhile(() => joinEmitted));
      return merge(join$, sourceFiltered$);
    };
  };

export class ControllableTimer {
  private delay = 0;
  private lastEmit = -1;

  readonly controlSource = new Subject<'reset' | 'resume' | 'pause'>();

  constructor(private readonly interval: number = 1000, private readonly scheduler: SchedulerLike = asyncScheduler) {
  }

  asObservable() {
    return this.controlSource.asObservable().pipe(
      distinctUntilChanged((previous, current) => current === previous && previous !== 'reset'),
      distinctUntilChanged((previous, current) => previous !== 'pause' && current === 'resume'),
      startWith('reset'),
      tap(control => {
        if (control === 'pause') {
          const elapsed = this.lastEmit >= 0 ? this.scheduler.now() - this.lastEmit : this.interval;
          this.delay = this.interval - elapsed;
        } else if (control === 'reset') {
          this.delay = 0;
        }
      }),
      switchMap(control => control === 'pause' ? EMPTY : timer(this.delay, this.interval, this.scheduler)),
      tap(() => this.lastEmit = this.scheduler.now()),
      map((_x, index) => index)
    );
  }
}

export const controllableTimer = (interval: number = 1000, scheduler: SchedulerLike = asyncScheduler) => {
  const timerSource = new ControllableTimer(interval, scheduler);
  return {timer$: timerSource.asObservable(), controlSource: timerSource.controlSource};
};
