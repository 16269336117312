import { Injectable, OnInit } from '@angular/core';
import { CountryConfigRestService } from '../rest-services/country-config-rest.service';
import { UserRestService } from '../rest-services/user-rest.service';
import { UserUtilService } from '../services/user/user-util.service';
import { combineLatest, forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AssignedCustomer } from '../models/assigned-customer';
import { roles } from '../core-constants.service';
import { forEach, includes, isEmpty, isEqual } from 'lodash-es';
import { Equipment } from '../models/equipment/equipment';
import { EquipmentRestService } from '../rest-services/equipment-rest.service';
import { StateService } from '../services/state.service';
import { ImpersonationCacheService } from '../services/cache/impersonation-cache.service';
import { SprConfigurationWidgetRestService } from '../rest-services/spr-configuration-widget-rest.service';
import { ServiceMetricsRestService } from '../rest-services/service-metrics-rest.service';
import { ServiceMetricsAvailable } from '../models/service-metrics-request';
import { WescanRestService } from '../../psr/services/wescan-rest.service';
import { AuthorizationService } from './authorization.service';
import { TeamplayRestService } from '../rest-services/teamplay-rest.service';

@Injectable({providedIn: 'root'})
export class CheckPermissionOrRoleService implements OnInit {

  userCountry = new ReplaySubject<string>(1);
  rolesToCheck: any;

  isLoadedSubject = new Subject<boolean>();

  reportingLink: string;
  psrLink: string;
  psrSet = false;

  isLoaded: boolean;
  headerTranslateLabel: string;
  currentStateName: string;

  showReportingLink = false;
  showReportingComponent = false;
  showServiceMetricsComponent = false;

  protected readonly unsubscribe$ = new Subject<void>();
  submittedWidget = false;
  isWeScanUser = false;

  constructor(
    private configService: CountryConfigRestService,
    private userRestService: UserRestService,
    private userUtilService: UserUtilService,
    private equipmentRestService: EquipmentRestService,
    private impersonationCacheService: ImpersonationCacheService,
    private stateService: StateService,
    private sprConfigurationWidgetRestService: SprConfigurationWidgetRestService,
    private metricsService: ServiceMetricsRestService,
    private weScanRestService: WescanRestService,
    private teamplayRestService: TeamplayRestService,
    public authorizationService: AuthorizationService) {
  }

  ngOnInit(): void {
    this.initProperties();
  }

  destroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadingPermissionForTabs() {

    /* Be aware: In case of calls which are country/language dependent you should not use cached values for toggles,
     because when country/language is called then it will not return just value but an observable which will not be triggered.
     */
    const config$ = this.configService.getConfig();
    const assignedCustomers$ = this.userRestService.getAssignedCustomers();
    const equipments$ = this.equipmentRestService.getEquipment();
    const user$ = this.userRestService.getUser();
    const submittedWidgets$ = this.sprConfigurationWidgetRestService.loadCustomerSubmittedWidgets();
    const isTeamplayAvailable$ = this.teamplayRestService.isTeamplayAvailable(false);

    combineLatest([config$, assignedCustomers$, equipments$, user$, submittedWidgets$, isTeamplayAvailable$]).pipe(
      tap((responses) => {
        this.userCountry.next(responses[3].country);
        this.submittedWidget = responses[4];
      }),
      switchMap(([config, assignedCustomers, equipments, , , isTeamplayAvailable]) => {
        return this.checkUserRoles(config, assignedCustomers, equipments, isTeamplayAvailable);
      }),
      take(1)
    ).subscribe();
  }

  checkUserRoles(config: Record<string, string>, assignedCustomers: AssignedCustomer[], equipments: Equipment[], isTeamplayAvailable: boolean): Observable<any> {
    return this.userUtilService.checkUserRoles(roles).pipe(
      switchMap(checkRolesResponse =>
        this.preloadConfigurations(config, checkRolesResponse)),
      tap(([checkRolesResponse, rwfWhitelistExists, serviceMetrics, isWeScanUser]) => {
        this.isLoadedSubject.next(false);
        this.isWeScanUser = isWeScanUser;
        this.checkDashboardRole(checkRolesResponse);
        this.checkEquipmentsRole(checkRolesResponse);

        this.checkTicketsRole(checkRolesResponse);
        this.checkActivitiesRole(checkRolesResponse, config);

        this.checkPsrRole(config, equipments, rwfWhitelistExists);

        this.checkInvoicesRole(checkRolesResponse, config);
        this.checkSystemUpdatesRole(checkRolesResponse);
        this.checkSecurityRole(checkRolesResponse, config);
        this.checkAdvisoriesRole(checkRolesResponse, config);
        this.checkImpersonationRole(checkRolesResponse);
        this.checkAdministrationRole(checkRolesResponse);
        this.checkContractRole(checkRolesResponse, config, assignedCustomers);
        this.checkPartnerOrdersRole(checkRolesResponse, assignedCustomers);
        this.checkDigitalCompassFeatureToggle(config);
        this.checkReportingTab(checkRolesResponse, config);
        this.checkReportingRolesAndTabs(checkRolesResponse, config, assignedCustomers, serviceMetrics);
        this.checkSprTab(checkRolesResponse, config);
        this.checkCreateTicketRole(checkRolesResponse);
        this.authorizationService.equipmentDocumentOverviewComponent.next(this.hasPermission(config.TOGGLE_EQUIPMENT_DOCUMENT_OVERVIEW));
        this.authorizationService.showTeamplayWidget.next(this.hasPermission(config.FEATURE_TOGGLE_DISPLAY_DASHBOARD_WIDGET_TP) && isTeamplayAvailable);
        this.checkEquipmentChecksheetTab(config);
        this.checkEquipmentSecurityTab(config);
        this.checkMasterAdminOnly(checkRolesResponse, config.FEATURE_TOGGLE_MARKETING_CONSENT, this.authorizationService.showMarketingConsentTrigger);
        this.checkMasterAdminOnly(checkRolesResponse, config.FEATURE_TOGGLE_UPDATE_SUBPROCESSOR_LIST, this.authorizationService.showUpdateSubprocessorHQ);
        this.checkEquipmentInvoicesTab(checkRolesResponse, config);
        this.checkCybersecurityOverview(checkRolesResponse, config);
        this.isLoaded = true;
        this.isLoadedSubject.next(true);
      }));
  }

  private checkReportingRolesAndTabs(checkRolesResponse, config, assignedCustomers: AssignedCustomer[], serviceMetrics: ServiceMetricsAvailable) {
    if (checkRolesResponse.viewEPSReports && isEqual(config.REPORTS_RENDER, 'true')) {
      this.checkReportingRoleAndCustomers(config, assignedCustomers, serviceMetrics);
    }
  }

  private checkCreateTicketRole(checkRolesResponse) {
    if (checkRolesResponse.createTicketRole) {
      this.authorizationService.createTicketRole$.next(true);
    }
  }

  private checkDashboardRole(checkRolesResponse) {
    const rolesSet = Object.keys(checkRolesResponse).filter(key => checkRolesResponse[key]);
    if (rolesSet.length === 2 && rolesSet.includes('userRole') && rolesSet.includes('securityAdvisoryViewXiaRole')) {
      this.authorizationService.showDashboardTab.next(false);
    } else {
      this.authorizationService.showDashboardTab.next(true);
    }
  }

  checkPsrRole(config, equipments: Equipment[], rwfWhitelistExists) {
    const allowedModalities = config['MODALITY_PSR_ALLOWED'].split(',');
    let psr = false;

    if (!isEmpty(allowedModalities)) {
      psr = this.hasPermission(config.PSR_FEATURE_AVAILABLE) && this.isAllowedByModality(equipments, allowedModalities);
    }

    if (this.isWeScanUser) {
      if (psr) {
        this.checkPsrTab(psr);
      }
      this.setWeScanTab();
    } else if (equipments.length > 0 && !isEmpty(allowedModalities)) {
      const psrRwf = this.hasPermission(config.FEATURE_TOGGLE_REMOTE_WORK_FORCE);

      if (psr || psrRwf) {
        this.checkPsrTab(psr);
        this.checkRwfTab(psrRwf, rwfWhitelistExists);
      } else {
        this.authorizationService.showPSRTab.next(false);
      }
    }
  }

  private checkPsrTab(psr: boolean) {
    if (psr) {
      this.psrLink = '/psr';
      this.authorizationService.showPSRTab.next(true);
      this.psrSet = true;
    }
  }

  private checkRwfTab(psrRwf: boolean, rwfWhitelistExists) {
    if (psrRwf) {
      if (rwfWhitelistExists) {
        if (this.psrSet) {
          this.authorizationService.showPSRandRWF.next(true);
          this.psrSet = false;
        } else {
          this.psrLink = '/psr/rwf';
          this.authorizationService.showPSRTab.next(true);
        }
      } else if (!this.psrSet) {
        this.authorizationService.showPSRTab.next(false);
      }
    }
  }

  private setWeScanTab() {
    if (this.psrSet) {
      this.authorizationService.showPSRandWeScan.next(true);
      this.psrSet = false;
    } else {
      this.psrLink = '/psr/wescan';
      this.authorizationService.showPSRTab.next(true);
    }
  }

  private isAllowedByModality(equipments: Equipment[], allowedModalities) {
    let isAllowedByModality = false;
    forEach(equipments, (equipment: Equipment) => {
      if (includes(allowedModalities, equipment.modality)) {
        isAllowedByModality = true;
        return false;
      }
    });
    return isAllowedByModality;
  }

  private preloadConfigurations(config, checkRolesResponse): Observable<[any, boolean, ServiceMetricsAvailable, boolean]> {
    return combineLatest([
      this.preloadRwfTabConfiguration(config, checkRolesResponse),
      this.preloadServiceMetricsTab(),
      this.checkIfUserIsWeScanUser()
    ]).pipe(
      map(preloadedDataLis => {
        return [checkRolesResponse, ...preloadedDataLis];
      })
    );
  }

  private preloadServiceMetricsTab(): Observable<ServiceMetricsAvailable> {
    return this.metricsService.isServiceMetricsAvailable().pipe(catchError(() => of(null)));
  }

  private preloadRwfTabConfiguration(config, checkRolesResponse): Observable<boolean> {
    return this.checkIfRWFwhiteListExists(config, checkRolesResponse);
  }

  // Because our fear is our best friend,
  // we do catch errors of failing requests and provide fallback values so other tabs still can be shown.
  // Think twice when removing fallback
  checkIfRWFwhiteListExists(config: any, role: any): Observable<boolean> {
    return this.hasPermission(config.FEATURE_TOGGLE_REMOTE_WORK_FORCE) && role.viewEquipmentRole
      ? this.equipmentRestService.getRemoteWorkForceEquipmentWhiteListExists().pipe(catchError(() => [false]))
      : of(false);
  }

  /**
   * The tab CONTRACT_TITLE shall be visible if the user has the
   * role ER_CONTRACT_VIEWCONTRACT and
   * if (the country has a 1:N contract model (CONTRACT_MODEL_ONE_MANY = true)
   * OR the customer is a LD customer).
   */
  private checkContractRole(checkRolesResponse: any, config: any, assignedCustomers: AssignedCustomer[]) {
    if (checkRolesResponse.viewContractRole && (this.hasPermission(config.CONTRACT_MODEL_ONE_MANY) ||
      assignedCustomers.some(customer => customer.isLD))) {
      this.authorizationService.showContractsTab.next(true);
    } else {
      this.authorizationService.showContractsTab.next(false);
    }
  }

  private checkAdvisoriesRole(checkRolesResponse, config) {
    if (this.hasPermission(config.SECURITY_ADVISORY_PAGE_FEATURE_TOGGLE)) {
      if (!this.hasPermission(config.FEATURE_TOGGLE_ADVISORY_SUBSCRIPTION) &&
        !this.hasPermission(config.FEATURE_TOGGLE_XIA_VIEW_ADVISORIES)) {
        this.authorizationService.showAdvisoriesTab.next(true);
      } else {
        if (checkRolesResponse.securityAdvisoryViewRole || checkRolesResponse.securityAdvisoryViewXiaRole) {
          this.authorizationService.showAdvisoriesTab.next(true);
        }
      }
    } else {
      this.authorizationService.showAdvisoriesTab.next(false);
    }
  }

  private checkSprTab(checkRolesResponse, config) {
    let sprTab = false;
    if (this.showSprTab(config)) {
      sprTab = true;
    } else if (this.hasPermission(config.TOGGLE_SPR_WIDGET_CONFIGURATION)) {
      if (!(checkRolesResponse.countryAdminRole || checkRolesResponse.masterAdminRole
        || checkRolesResponse.customerAdminRole)) {
        sprTab = this.submittedWidget;
      } else {
        sprTab = true;
      }
    }
    this.authorizationService.showSprTab.next(sprTab);
  }

  private showSprTab(config) {
    return isEqual(config.TOGGLE_SPR_REMOTE_ACTIVITY_WIDGET_ALLOWED, 'true') ||
      isEqual(config.FEATURE_TOGGLE_TUBE_GUARD_WIDGET, 'true') ||
      isEqual(config.FEATURE_TOGGLE_GUARDIAN_WIDGET, 'true') ||
      isEqual(config.FEATURE_TOGGLE_HELIUM_LEVEL_WIDGET, 'true');
  }

  private checkAdministrationRole(checkRolesResponse) {
    if (checkRolesResponse.countryAdminRole || checkRolesResponse.masterAdminRole
      || checkRolesResponse.customerAdminRole) {
      this.authorizationService.showCustAdminTab.next(true);
    } else {
      this.authorizationService.showCustAdminTab.next(false);
    }
  }

  private checkPartnerOrdersRole(checkRolesResponse: any, assignedCustomers: AssignedCustomer[]) {
    if (checkRolesResponse.partnerOrdersRole && checkRolesResponse.partnerOrdersDetailsRole &&
      assignedCustomers.some(customer => (customer.typeId === 2) || (customer.typeId === 3))) {
      this.authorizationService.showPartnerOrderTab.next(true);
    } else {
      this.authorizationService.showPartnerOrderTab.next(false);
    }
  }

  private checkInvoicesRole(checkRolesResponse, config) {
    if (checkRolesResponse.viewInvoicesRole && this.hasPermission(config.INVOICES_AVAILABLE)) {
      this.authorizationService.showInvoicesTab.next(true);
    } else {
      this.authorizationService.showInvoicesTab.next(false);
    }
  }

  private checkEquipmentInvoicesTab(checkRolesResponse, config) {
    const showEquipmentInvoiceTab = checkRolesResponse.viewInvoicesRole && this.hasPermission(config.TOGGLE_EQUIPMENT_INVOICES_TAB);
    this.authorizationService.showEquipmentInvoicesTab.next(showEquipmentInvoiceTab);
  }

  private checkSecurityRole(checkRolesResponse, config) {
    if (checkRolesResponse.cybersecurityDashboardRole &&
      this.hasPermission(config.SHOW_SECURITY_INFO_TAB) &&
      this.hasPermission(config.TOGGLE_VULNERABILITY_ASSESSMENTS)) {
      this.authorizationService.showSecurityTab.next(true);
    } else {
      this.authorizationService.showSecurityTab.next(false);
    }
  }

  private checkEquipmentsRole(checkRolesResponse) {
    if (checkRolesResponse.viewEquipmentRole) {
      this.authorizationService.showEquipmentTab.next(true);
    } else {
      this.authorizationService.showEquipmentTab.next(false);
    }
  }

  private checkTicketsRole(checkRolesResponse) {
    if (checkRolesResponse.viewTicketRole) {
      this.authorizationService.showOpenTicketsTab.next(true);
    } else {
      this.authorizationService.showOpenTicketsTab.next(false);
    }
  }

  private checkSystemUpdatesRole(checkRolesResponse) {
    if (checkRolesResponse.itAdminRole || checkRolesResponse.viewEquipmentRole) {
      this.authorizationService.showSystemUpdatesTab.next(true);
    } else {
      this.authorizationService.showSystemUpdatesTab.next(false);
    }
  }

  private checkImpersonationRole(checkRolesResponse) {
    if (checkRolesResponse.impersonateUserRole) {
      this.authorizationService.showImpersonationTab.next(true);
      this.preLoadCustomerImpersonationTabData();
    } else {
      this.authorizationService.showImpersonationTab.next(false);
    }
  }

  private preLoadCustomerImpersonationTabData() {
    this.userCountry.pipe(
      mergeMap((userCountry: string) => {
        this.impersonationCacheService.clearCacheByCountry(userCountry);

        return forkJoin([this.impersonationCacheService.getGroupListByCountry(userCountry),
          this.impersonationCacheService.getCustomerListByCountry(userCountry)]);
      }),
      take(1)
    ).subscribe();
  }

  private initProperties() {
    this.reportingLink = '';
    this.isLoaded = false;
    this.headerTranslateLabel = 'MY_DASHBOARD';
    this.currentStateName = this.stateService.getStateNameFromWindowLocation();
  }

  hasPermission(value: any) {
    return isEqual(value, 'true');
  }

  private checkActivitiesRole(checkRolesResponse: any, config) {
    if (checkRolesResponse.viewPlannedActivityRole || checkRolesResponse.viewPlannedTrainingRole) {
      if (this.hasPermission(config.FEATURE_TOGGLE_P58_APPOINTMENTS)) {
        this.authorizationService.showPlannedAppointmentsTab.next(true);
        this.authorizationService.showPlannedActivityTab.next(false);
      } else {
        this.authorizationService.showPlannedActivityTab.next(true);
        this.authorizationService.showPlannedAppointmentsTab.next(false);
      }
    } else {
      this.authorizationService.showPlannedActivityTab.next(false);
      this.authorizationService.showPlannedAppointmentsTab.next(false);
    }
  }

  private checkDigitalCompassFeatureToggle(config) {
    if (this.hasPermission(config.FEATURE_TOGGLE_DIGITAL_COMPASS)) {
      this.authorizationService.showDigitalCompassTab.next(true);
    } else {
      this.authorizationService.showDigitalCompassTab.next(false);
    }
  }

  private checkReportingTab(checkRolesResponse, config) {
    if (checkRolesResponse.viewEPSReports && isEqual(config.REPORTS_RENDER, 'true')) {
      this.authorizationService.showReportingTab.next(true);
    } else {
      this.authorizationService.showReportingTab.next(false);
    }
  }

  private checkEquipmentChecksheetTab(config) {
    if (this.hasPermission(config.FEATURE_TOGGLE_DAILY_CHECK_SHEET_TAB)) {
      this.authorizationService.showEquipmentCheckSheetTab.next(true);
    } else {
      this.authorizationService.showEquipmentCheckSheetTab.next(false);
    }
  }

  private checkEquipmentSecurityTab(config) {
    if (this.hasPermission(config.SHOW_SECURITY_INFO_TAB)) {
      this.authorizationService.showEquipmentSecurityTab.next(true);
    } else {
      this.authorizationService.showEquipmentSecurityTab.next(false);
    }
  }

  /**
   * @description
   * Check if user has customers assigned and then set correct reporting link.
   * Otherwise, show Modal that no customers are assigned
   */
  checkReportingRoleAndCustomers(config, assignedCustomers: AssignedCustomer[], serviceMetricsAvailable: any) {
    this.showServiceMetricsComponent = false;
    this.showReportingComponent = false;
    if (assignedCustomers.length > 0) {
      if (serviceMetricsAvailable && serviceMetricsAvailable.available) {
        this.showServiceMetricsComponent = true;
      } else {
        this.showReportingComponent = isEqual(config.LIFENET_OWN_REPORTS_AVAILABLE, 'true');
        this.showReportingLink = !this.showReportingComponent;
        if (this.showReportingLink) {
          const customerIds = assignedCustomers.map(({customerId}) => customerId).join(',');
          this.reportingLink = `${config.LINK_REPORTING}?CUSTOMERS=${customerIds}`;
        }
      }
    }
  }

  private checkMasterAdminOnly(userRoles: any, toggle: any, subject: ReplaySubject<boolean>) {
    if (this.hasPermission(toggle) && userRoles.masterAdminRole) {
      subject.next(true);
    } else {
      subject.next(false);
    }
  }

  checkIfUserIsWeScanUser(): Observable<boolean> {
    return this.weScanRestService.isWeScanUser().pipe(catchError(() => [false]));
  }

  private checkCybersecurityOverview(checkRolesResponse, config) {
    this.authorizationService.showCybersecurityOverview.next(checkRolesResponse.cybersecurityDashboardRole &&
      this.hasPermission(config.FEATURE_TOGGLE_CYBERSEC_DASHBOARD));
  }
}
