<hl-progress-indicator [loading]="!isLoaded"></hl-progress-indicator>
@if (isLoaded && myFiltersForm) {
  <form [formGroup]="myFiltersForm">
    <div class="margin-bottom-1 margin-left-tiny-5">
      <p [translate]="'MY_FILTERS_TAB_INFO'"></p>
    </div>
    <div class="collection overview-list__items ng-star-inserted">
      <div class="collection-item list-item">
        <div class="toggle-switch my-equipment-switch" data-cy="only-my-equipment-toggle">
          <label for="my-equipment-switch-toggle" class="toggle-switch__label my-equipment-switch__label">
            <i class="icon-favorite"></i>
            <span translate="GENERIC_LABEL_ONLY_MY_SIEMENS_EQUIPMENT" class="color-black-80"></span>
          </label>
        </div>
        <div class="collection__additional-content list-item__toolbar align-items-center">
          <input type="checkbox" id="my-equipment-switch-toggle" class="toggle-switch__checkbox" formControlName="myEquipmentSwitch">
          <label for="my-equipment-switch-toggle" class="toggle-switch__label my-equipment-switch__label"></label>
        </div>
      </div>
    </div>
    <div class="row margin-left-tiny-1">
      <div class="col-8">
        <h5><span translate="GENERIC_LABEL_MY_FAVORITE_MODALITIES"></span></h5>
      </div>
      <div class="col-4 padding-top-1">
        @if (hasMoreItemsThanOne()) {
          @if (isSelectAllAvailable()) {
            <button class="button button--small float-right margin-right-1" (click)="toggleAllItems(true)"
              [translate]="'GENERIC_LABEL_SELECT_ALL'"></button>
          } @else {
            <button class="button button--small float-right margin-right-tiny-8" (click)="toggleAllItems(false)"
              [translate]="'GENERIC_LABEL_DESELECT_ALL'"></button>
          }
        }
      </div>
    </div>
    <div class="collection overview-list__items ng-star-inserted border-none margin-left-1">
      @for (modality of allModalities; track modality; let i = $index) {
        <div class="collection-item list-item border-none padding-top-0">
          <div class="toggle-switch">
            <label for="modality-switch-toggle-{{i}}" class="toggle-switch__label">
              <span> {{ modality.modalityName }} </span>
            </label>
          </div>
          <div class="collection__additional-content list-item__toolbar align-items-center">
            <input type="checkbox" id="modality-switch-toggle-{{i}}" class="toggle-switch__checkbox position-relative"
              (change)="handleToggleModality(modality.modalityCodes, $event)" [checked]="isToggleChecked(modality.modalityCodes)">
            <label for="modality-switch-toggle-{{i}}" class="toggle-switch__label"></label>
          </div>
        </div>
      }
    </div>
  </form>
}
