import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { difference, flatMap, intersection, isEqual, union } from 'lodash-es';
import { MyFiltersService } from '../../core/services/my-filters.service';

@Component({
  selector: 'hl-my-filters-tab',
  templateUrl: './my-filters-tab.component.html'
})
export class MyFiltersTabComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  isLoaded = false;
  allModalities: { modalityName: string, modalityCodes: string[]}[] = [];
  allModalityCodes: string[] = [];

  myFiltersForm = this.fb.group({
    myEquipmentSwitch: [false],
    favoriteModalities: this.fb.array([])
  });

  get isChanged() {
    return this.myFiltersForm && !isEqual(this.myFiltersForm.value, this.service.myFiltersValue);
  }

  get favoriteModalities() {
    return this.myFiltersForm.get('favoriteModalities') as UntypedFormArray;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private service: MyFiltersService
  ) {}

  ngOnInit() {
    combineLatest([
      this.service.myFilters$,
      this.service.getUserModalitiesList()
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([{ favoriteModalities, ...myFilters }, modalities]) => {
      this.isLoaded = true;
      this.allModalities = modalities;
      this.allModalityCodes = flatMap(this.allModalities, m => m.modalityCodes);
      this.myFiltersForm.patchValue(myFilters);
      this.initFavoriteModalities(favoriteModalities);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save() {
    this.service.save({ ...this.myFiltersForm.value, overallSwitch: true });
  }

  reset() {
    const { myFiltersValue } = this.service;
    this.myFiltersForm.patchValue(myFiltersValue);
    this.initFavoriteModalities(myFiltersValue.favoriteModalities || []);
  }

  handleToggleModality(modalityCodes: string[], event: Event) {
    const checked = (event && (event.target as HTMLInputElement).checked);
    this.toggleModality(modalityCodes, checked);
  }

  private toggleModality(favoriteModalityCodes: string[], add: boolean) {
    const currentModalityCodes = this.favoriteModalities.value as string[];
    const newModalityCodes = (add ? union : difference)(currentModalityCodes, favoriteModalityCodes);
    this.replaceFavoriteModalities(newModalityCodes);
  }

  private replaceFavoriteModalities(favoriteModalityCodes: string[]) {
    this.favoriteModalities.clear();
    for (const modalityCode of favoriteModalityCodes) {
      this.favoriteModalities.push(new UntypedFormControl(modalityCode));
    }
  }

  isToggleChecked(modalityCodes) {
    return intersection(modalityCodes, this.favoriteModalities.value).length !== 0;
  }

  hasMoreItemsThanOne(): boolean {
    return (this.allModalities && this.allModalities.length > 1);
  }

  isSelectAllAvailable(): boolean {
    return this.favoriteModalities.value.length < this.allModalityCodes.length;
  }

  toggleAllItems(selectAll: boolean) {
    this.toggleModality(this.allModalityCodes, selectAll);
  }

  initFavoriteModalities(favoriteModalities) {
    this.replaceFavoriteModalities(favoriteModalities);
  }
}
