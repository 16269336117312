<div class="scrollable-content scrollable-content--with-pagination dummy-scrolling-selector-class">
  @if (showUserSettingsInTabs) {
    <div>
      <hl-tabs>
        @if (showMyCustomersTab) {
          <a (click)="onTabClick('myCustomers')" class="tabs__item" [class.is-active]="currentStateName === 'settings-myCustomers'"
            [translate]="'LABEL_MY_INSTITUTIONS_SETTINGS_MENU'">
          </a>
        }
        <a (click)="onTabClick('notifications')" class="tabs__item" [class.is-active]="currentStateName === 'settings-notifications'"
          [translate]="'LABEL_MY_NOTIFICATION_SETTINGS_MENU'">
        </a>
        @if (showMyFiltersTab) {
          <a (click)="onTabClick('myFilters')" class="tabs__item" [class.is-active]="currentStateName === 'settings-myFilters'"
            [translate]="'LABEL_MY_FILTERS_SETTINGS_MENU'" data-cy="my-filters-tab-button">
          </a>
        }
      </hl-tabs>
      <div>
        <hl-my-customers-tab [class.display-none]="shownOrHiddenComponent('settings-myCustomers')"
          (afterSaveEmit)="reloadOrRedirectLifenetAfterSave()"></hl-my-customers-tab>
        <hl-notifications-tab [class.display-none]="shownOrHiddenComponent('settings-notifications')"></hl-notifications-tab>
        <hl-my-filters-tab [class.display-none]="shownOrHiddenComponent('settings-myFilters')"></hl-my-filters-tab>
      </div>
    </div>
  } @else {
    <hl-notifications-tab class="notification-tab"></hl-notifications-tab>
  }
</div>

<section class="my-customer-panel--sticky-footer">
  <button id="user-settings-save-button" class="button button--primary" [disabled]="shouldSaveButtonBeDisabled()" (click)="saveSetting()"
    [translate]="'GENERIC_LABEL_SAVE'" data-cy="my-filter-save-button"></button>
  @if (shouldShowAddFavoriteFilterModal) {
    <button class="button margin-left-tiny-5" (click)="showAddFavoriteModal()" [translate]="'FAVORIT_SAVE_AS'"></button>
  }
</section>
<hl-cancel-modal #cancelModal (yesClick)="saveSetting()" (noClick)="close()"
  [headerMessage]="'LABEL_MY_NOTIFICATION_SETTINGS_CONFIRMATION_CHANGES_HEADLINE'"
  [descriptionMessage]="'LABEL_MY_NOTIFICATION_SETTINGS_CONFIRMATION_CHANGES'"></hl-cancel-modal>
