import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CountryConfigRestService } from 'app/core/rest-services/country-config-rest.service';
import { EquipmentUtilService } from 'app/core/services/equipment/equipment-util.service';
import { UserUtilService } from 'app/core/services/user/user-util.service';
import { StateService } from 'app/core/services/state.service';
import { LogService } from 'app/core/services/log/log.service';
import { Router } from '@angular/router';
import { BrowserStateService } from 'app/core/services/browser-state.service';
import { FilterUtilService } from 'app/core/utils/filter-util.service';
import { ImpersonationUtilsService } from 'app/core/utils/impersonation-utils.service';
import { MyCustomersRestService } from 'app/core/rest-services/my-customers-rest.service';
import { OverlayCommunicationService } from 'app/core/component-communication-services/overlay-close/overlay-communication.service';
import { MyCustomer } from 'app/core/models/customer/my-customer';
import { CustomerConstantsService } from 'app/core/services/customer/customer-constants.service';
import { BaseListView } from 'app/core/base-class/base-list-view';
import { User } from 'app/core/models/user';
import { SelectOption } from 'app/core/models/select-option';
import { cloneDeep, includes, isEqual } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { AddFavoriteModalComponent } from 'app/shared/modal-popup/add-favorite-modal/add-favorite-modal.component';
import { RenameFavoriteModalComponent } from 'app/shared/modal-popup/rename-favorite-modal/rename-favorite-modal.component';
import { DeleteFavoriteModalComponent } from 'app/shared/modal-popup/delete-favorite-modal/delete-favorite-modal.component';

enum SortSkeletonSelectEnum {
  WithState,
  WithoutState
}

@Component({
  selector: 'hl-my-customers-tab',
  templateUrl: './my-customers-tab.component.html'
})
export class MyCustomersTabComponent extends BaseListView<MyCustomer> implements OnDestroy {
  @ViewChild('addFavoriteModal')
  addFavoriteModal: AddFavoriteModalComponent;
  @ViewChild('renameFavoriteModal')
  renameFavoriteModal: RenameFavoriteModalComponent;
  @ViewChild('deleteFavoriteModal')
  deleteFavoriteModal: DeleteFavoriteModalComponent;

  user: User;
  selectedCountry;
  countries: SelectOption[];
  showCountries = false;

  isChanged = false;

  listType = 'my-institutions';
  toggledOnCustomersFromSavedFilter: [];
  showFavoriteFilters = false;
  isInitialized = false;

  selector = '.dummy-scrolling-selector-class';
  sortSkeletonSelector: SortSkeletonSelectEnum;

  baseList: Map<string, boolean>;

  @Output()
  afterSaveEmit: EventEmitter<any> = new EventEmitter();

  static isMyCustomerMissingOrTrue(customer: MyCustomer): boolean {
    return customer.myCustomer === undefined || customer.myCustomer === null || customer.myCustomer;
  }

  constructor(
    configService: CountryConfigRestService,
    equipmentUtilService: EquipmentUtilService,
    userUtilService: UserUtilService,
    stateService: StateService,
    logService: LogService,
    router: Router,
    browserStateService: BrowserStateService,
    filterUtilService: FilterUtilService,
    private impersonationUtils: ImpersonationUtilsService,
    private customerConstantsService: CustomerConstantsService,
    private httpMyCustomersService: MyCustomersRestService,
    private overlayCommunicationService: OverlayCommunicationService
  ) {
    super(
      configService,
      equipmentUtilService,
      userUtilService,
      stateService,
      logService,
      router,
      browserStateService,
      filterUtilService
    );
    this.baseList = new Map<string, boolean>();
    this.selectedCountry = '';
  }

  ngOnDestroy() {
    super.destroy();
  }

  init() {
    this.userUtilService.getUser()
      .pipe(finalize(() => super.init()))
      .subscribe(user => {
        this.user = user;
        this.checkShowCountriesDropDownAndSetDropDownList();
      });
    this.isInitialized = true;
  }

  checkShowCountriesDropDownAndSetDropDownList() {
    if (this.impersonationUtils.getUserPreferenceCountry() &&
      this.impersonationUtils.getUserPreferenceCountry() !== '') {
      this.selectedCountry = this.impersonationUtils.getUserPreferenceCountry();
    } else {
      this.selectedCountry = this.user.country;
      this.impersonationUtils.setUserPreferenceCountry(this.selectedCountry);
    }

    // no need to show countries in drop down
    if (this.user.countries.length > 1) {
      this.showCountries = true;
      this.countries = this.impersonationUtils.generateCountryTranslationMapping(
        this.user.countries,
        true
      );
    }
  }

  afterConfigProperties(config: any): void {
    this.showFavoriteFilters = isEqual(config.FEATURE_TOGGLE_MY_INSTITUTIONS_FAVORITES, 'true');
  }

  afterInitProperties(): void {
    this.sortSkeleton = this.customerConstantsService.getMyCustomersWithoutStateSortSkeleton();
    this.sortSkeletonSelector = SortSkeletonSelectEnum.WithoutState;
  }

  afterInitViewModelList(): void {
    this.onAdvancedFilterChange();
  }

  getEmptyListUrl(): string {
    return '';
  }

  getFilterObject(): any {
    return {
      search: {
        searchValue: this.searchInput,
        searchColumns: ['customerName', 'street', 'city', 'postalCode', 'state']
      },
      orderBy: this.sortSkeleton.sortObject,
      country: this.selectedCountry,
      toggledOnCustomers: this.getToggledOnCustomerIds()
    };
  }

  private getToggledOnCustomerIds(): string[] {
    return this.rawList
      .filter(customer => customer.myCustomer)
      .map(customer => customer.customerId);
  }

  initAdvancedFilterDropDownList(): void {
    // intentionally left empty
  }

  /**
   * Override of the method, to fix the cloning and use proper Deep Cloning
   */
  initLoadViewModelList() {
    this.isLoaded = false;
    this.viewModelSubscription = this.loadViewModelList()
      .pipe(finalize(() => {
        this.isLoaded = true;
      }))
      .subscribe({
        next: viewModelResponse => {
          // clone deep not to change original
          this.rawList = cloneDeep(viewModelResponse);
          this.applyFavoriteIfNeeded();
          this.toggledOnCustomersFromSavedFilter = undefined;

          this.afterInitViewModelList();
        },
        error: some => {
          this.logService.debug('what is the problem? 8' + some);
        }}
      );
  }

  loadViewModelList(): Observable<MyCustomer[]> {
    if (!this.selectedCountry) {
      return of([]);
    }

    this.isChanged = false;

    const myCustomersListByCountryObservable = this.httpMyCustomersService
      .getMyCustomersByCountry(this.selectedCountry);

    myCustomersListByCountryObservable.subscribe((result) => {
      const shouldSortByState = result.some(customer => !!customer.state);

      if (shouldSortByState) {
        this.sortSkeleton = this.customerConstantsService.getMyCustomersWithStateSortSkeleton();
        this.sortSkeletonSelector = SortSkeletonSelectEnum.WithState;
      } else if (!shouldSortByState) {
        this.sortSkeleton = this.customerConstantsService.getMyCustomersWithoutStateSortSkeleton();
        this.sortSkeletonSelector = SortSkeletonSelectEnum.WithoutState;
      }

      this.baseList = new Map(result.map(customer =>
        [customer.customerId, MyCustomersTabComponent.isMyCustomerMissingOrTrue(customer)] as [string, boolean]
      ));
    });

    return myCustomersListByCountryObservable;
  }

  navigate(): void {
    // intentionally left empty
  }

  setDerivedBoundPropertiesFromFavorite(favorite: any): void {
    this.selectedCountry = favorite.country;
    this.toggledOnCustomersFromSavedFilter = favorite.toggledOnCustomers;
    this.applyFavoriteIfNeeded();
  }

  private applyFavoriteIfNeeded() {
    if (this.toggledOnCustomersFromSavedFilter) {
      this.rawList.forEach(customer => {
        customer.myCustomer = includes(this.toggledOnCustomersFromSavedFilter, customer.customerId);
      });
    }
    this.checkIfValuesChanged();
  }

  setPropertiesFromQueryParams(config: any) {
    // intentionally left empty
  }

  shouldSelectActive(): boolean {
    return false;
  }

  resetElements(): void {
    this.searchInput = '';
    this.init();
  }

  onSelectedCountryChanged() {
    this.impersonationUtils.setUserPreferenceCountry(this.selectedCountry);
    this.initLoadViewModelList();
  }

  trackByFn(index, item: MyCustomer) {
    return item['customerId'];
  }

  checkIfValuesChanged() {
    const customerFromRawList =
      this.rawList.find((customer) => customer.myCustomer !== this.baseList.get(customer.customerId));

    this.isChanged = !!customerFromRawList;
  }

  toggleMyCustomer(event, myCustomer: MyCustomer) {
    event.preventDefault();

    const newVal = !(myCustomer.myCustomer);

    this.rawList[
      this.rawList.findIndex(customer =>
        customer.customerId === myCustomer.customerId
      )].myCustomer = newVal;

    myCustomer.myCustomer = newVal;

    this.checkIfValuesChanged();
  }

  private setAllMyCustomersToSameBooleanValue(myCustomerValue: boolean): void {
    if (this.searchInput === '') {
      this.rawList.forEach(customer => customer.myCustomer = myCustomerValue);
    } else {
      this.listWithoutPagination.forEach(customer => customer.myCustomer = myCustomerValue);
    }

    this.checkIfValuesChanged();
  }

  selectAllItems() {
    this.setAllMyCustomersToSameBooleanValue(true);
  }

  unselectAllItems() {
    this.setAllMyCustomersToSameBooleanValue(false);
  }

  hasMoreItemsThanOne(): boolean {
    return (this.listWithoutPagination && this.listWithoutPagination.length > 1);
  }

  isSelectAllAvailable(): boolean {
    return this.listWithoutPagination.some((customer) => (customer.myCustomer === false));
  }

  isDeselectAllAvailable(): boolean {
    return !this.listWithoutPagination.some((customer) => (customer.myCustomer === false));
  }

  save() {
    this.isLoaded = false;
    const wasChanged = this.isChanged;
    this.isChanged = false;

    const newList = [];
    this.rawList.forEach(customer => {
      const oldValue = this.baseList.get(customer.customerId);
      const newValue = customer.myCustomer;
      if (oldValue !== undefined && oldValue !== null &&
        newValue !== undefined && newValue !== null
        && oldValue !== newValue) {
        newList.push({
          customerId: customer.customerId,
          myCustomer: newValue
        });
      }
    });
    if (newList && newList.length > 0) {
      this.httpMyCustomersService.postMyCustomersByCountry(newList, this.selectedCountry).subscribe({
        next: () => {
          this.isLoaded = true;
          this.overlayCommunicationService.emitCloseOverlayAfterSave(null);
          this.afterSaveEmit.emit(null);
        },
        error: () => {
          this.isChanged = wasChanged;
        }
      });
    } else {
      this.isLoaded = true;
      this.overlayCommunicationService.emitCloseOverlayAfterSave(null);
    }
  }
}
